import { graphql } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const NotFoundPage = ({ data: { allContentfulSiteSettings, microcopy } }) => {
  const siteSettings = allContentfulSiteSettings.edges[0].node;

  const localizedMicrocopy = {};

  microcopy.edges
    .map(edge => edge.node)
    .forEach(node => {
      if (node.node_locale === siteSettings.node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  siteSettings.microcopy = localizedMicrocopy;
  return (
    <Layout siteSettings={siteSettings}>
      <SEO title="404: Not found" />
      <Container fluid>
        <div className="row">
          <div className="col-12">
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query siteSettings404 {
    microcopy: allContentfulMicrocopy {
      edges {
        node {
          node_locale
          key
          value
        }
      }
    }
    allContentfulSiteSettings {
      ...settings
    }
  }
`;

export default NotFoundPage;
